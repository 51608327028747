/**
 * These colors come from /client/themes/deprecated-default-theme
 */
:root {
  --color-green-700: #00783e; /* deprecatedDefaultTheme.palette.green.dark */
  --color-green-500: #47b881; /* deprecatedDefaultTheme.palette.green.base */

  --color-blue-600: #084b8a; /* deprecatedDefaultTheme.palette.blue.dark */
  --color-blue-500: #1070ca; /* deprecatedDefaultTheme.palette.blue.base */
  --color-blue-300: #ddebf7; /* deprecatedDefaultTheme.palette.blue.light */
  --color-blue-50: #f7f9fd; /* deprecatedDefaultTheme.palette.blue.lightest */

  --color-peach-50: #fcf1e8;

  --color-pink-500: #e365a6;

  --color-grey-500: #234361; /* deprecatedDefaultTheme.palette.neutral.dark */
  --color-grey-300: #425a70; /* deprecatedDefaultTheme.palette.neutral.base */
  --color-grey-200: #e4e7eb; /* deprecatedDefaultTheme.palette.neutral.light */
  --color-grey-100: #f9f9fb; /* deprecatedDefaultTheme.palette.neutral.lightest */

  --color-border: #e4e7eb; /* deprecatedDefaultTheme.scales.neutral.N4 */
  --color-tint: #f9f9fb; /* deprecatedDefaultTheme.colors.background.tint1 */
  --color-tint2: #f5f6f7; /* deprecatedDefaultTheme.colors.background.tint2 */
  --color-white: #ffffff;
  --color-danger: #ec4c47; /* deprecatedDefaultTheme.colors.intent.danger */
  --color-title-text: #234361; /* deprecatedDefaultTheme.colors.text.dark */
  --color-body-text: #425a70; /* deprecatedDefaultTheme.colors.text.default */
  --color-muted-text: #66788a; /* deprecatedDefaultTheme.colors.text.muted */

  --font-sans: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --font-mono: 'SF Mono', 'Monaco', 'Inconsolata', 'Fira Mono',
    'Droid Sans Mono', 'Source Code Pro', monospace;

  /* typography headings 500 */
  --ts-display-4-fs: 16px;
  --ts-display-4-fw: 500;
  --ts-display-4-lh: 20px;
  --ts-display-4-ls: -0.05px;

  /* typography headings 400 */
  --ts-display-5-fs: 14px;
  --ts-display-5-fw: 600;
  --ts-display-5-lh: 20px;
  --ts-display-5-ls: -0.05px;

  /* typography text 400 */
  --ts-body-1-fs: 14px;
  --ts-body-1-fw: 400;
  --ts-body-1-lh: 20px;
  --ts-body-1-ls: -0.05px;

  /* typography text 300 */
  --ts-body-2-fs: 12px;
  --ts-body-2-fw: 400;
  --ts-body-2-lh: 16px;
  --ts-body-2-ls: 0;

  --radius-1: 4px;
  --radius-2: 8px;

  --shadow-1-black: 0 0 1px rgba(67, 90, 111, 0.81),
    0 5px 8px -4px rgba(67, 90, 111, 0.3);
  --shadow-2-black: 0 0 1px rgba(67, 90, 111, 0.81),
    0 2px 4px -2px rgba(67, 90, 111, 0.3);

  --easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
  --easing-spring: cubic-bezier(0.175, 0.885, 0.32, 1.175);

  --animation-fast: 225ms;
  --animation-slow: 400ms;
}

:root {
  --z-index-base: 0; /* orbAnimation */
  --z-index-top: 1; /* orbAnimation, Filters, EventPropertiesTable, WorkspaceActivityFeed, Avatar, UpgradePrompt */
  --z-index-form: 2; /* Traits form, Button, FeaturesEditor, Form, react-forms */
  --z-index-sidenav: 9; /* Side nav should be under react-ui-library Dialog z-index 10 (WorkspaceDeleteAPP) */
  --z-index-fullscreenModal: 10; /* orbAnimation, FullscreenModal */
  --z-index-overlay: 100; /* Modal, Overlay, Sheet,  */
}

/**
 * Text Styles
 */

:root {
  --book: 400;
  --medium: 500;
  --bold: 600;
}

/**
 * Old Spacing
 */

:root {
  --space-5: 4rem; /* 4rem */
  --space-4: 2rem; /* 2rem */
  --space-3: 1rem; /* 1rem */
  --space-2: 0.5rem; /* 0.5rem */
  --space-1: 0.25rem; /* 0.25rem */
}

/**
 * Major (Spacing) Scale
 * Based on a 8px grid. Meaning everything is a multiple of 8.
 */

:root {
  --major-scale-1: 8px;
  --major-scale-2: 16px;
  --major-scale-3: 24px;
  --major-scale-4: 32px;
  --major-scale-5: 40px;
  --major-scale-6: 48px;
  --major-scale-7: 56px;
  --major-scale-8: 64px;
  --major-scale-9: 72px;
  --major-scale-10: 80px;
  --major-scale-11: 88px;
  --major-scale-12: 96px;
  --major-scale-13: 104px;
}

/**
 * Minor (Spacing) Scale
 * Sometimes the 8px grid is not enough.
 * Use only when major scale fails
 */

:root {
  --minor-scale-1: 4px;
  --minor-scale-2: 12px;
  --minor-scale-3: 20px;
}

/**
 * Evergreen colors
 */

:root {
  /** neutral */
  --evergreen-neutral-5: #f7f8fa;
  --evergreen-neutral-15: #e7ecf1;
  --evergreen-neutral-50: #c1ced9;
  --evergreen-neutral-150: #829cb4;
  --evergreen-neutral-3A: rgba(67, 90, 111, 0.025);
  --evergreen-neutral-5A: rgba(67, 90, 111, 0.041);
  --evergreen-neutral-7A: rgba(67, 90, 111, 0.057);
  --evergreen-neutral-10A: rgba(67, 90, 111, 0.079);
  --evergreen-neutral-15A: rgba(67, 90, 111, 0.114);
  --evergreen-neutral-20A: rgba(67, 90, 111, 0.146);
  --evergreen-neutral-30A: rgba(67, 90, 111, 0.204);
  --evergreen-neutral-40A: rgba(67, 90, 111, 0.255);
  --evergreen-neutral-50A: rgba(67, 90, 111, 0.301);
  --evergreen-neutral-60A: rgba(67, 90, 111, 0.342);
  --evergreen-neutral-70A: rgba(67, 90, 111, 0.38);
  --evergreen-neutral-80A: rgba(67, 90, 111, 0.415);
  --evergreen-neutral-90A: rgba(67, 90, 111, 0.447);
  --evergreen-neutral-100A: rgba(67, 90, 111, 0.477);
  --evergreen-neutral-125A: rgba(67, 90, 111, 0.544);
  --evergreen-neutral-150A: rgba(67, 90, 111, 0.602);
  --evergreen-neutral-200A: rgba(67, 90, 111, 0.699);
  --evergreen-neutral-300A: rgba(67, 90, 111, 0.845);

  /** red */
  --evergreen-red-100: #f9b59d;
  --evergreen-red-light: #fae2e2;

  /** orange */
  --evergreen-orange-700: #996a13;
  --evergreen-orange-500: #ffb020;

  /** green */
  --evergreen-green-100: #a7ddc3;
  --evergreen-green-500: #47b881;
  --evergreen-green-600: #3faf77;
  --evergreen-green-700: #37a56d;
  --evergreen-green-800: #2d9760;
  --evergreen-green-900: #248953;
  --evergreen-green-100A: rgba(71, 184, 129, 0.477);

  /** blue */
  --evergreen-blue-5: #f3f9ff;
  --evergreen-blue-10: #e7f3ff;
  --evergreen-blue-500: #016cd1;
  --evergreen-blue-1000: #00408f;
  --evergreen-blue-10A: rgba(1, 108, 209, 0.079);
  --evergreen-blue-15A: rgba(1, 108, 209, 0.114);
  --evergreen-blue-20A: rgba(1, 108, 209, 0.146);
  --evergreen-blue-80A: rgba(1, 108, 209, 0.415);
  --evergreen-blue-150A: rgba(1, 108, 209, 0.602);

  /** turquoise */
  --evergreen-turquoise-7: #f0fcfe;
}

:root {
  /**
   * Most borders should use extra-muted
   */
  --evergreen-color-border-extra-muted: var(--evergreen-neutral-10A);

  --evergreen-color-selectable-hover: var(--evergreen-neutral-5);
  --evergreen-color-selectable-focus: var(--evergreen-blue-5);
  --evergreen-color-selectable-active: var(--evergreen-blue-10);
}
