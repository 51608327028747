@import '../../variables.css';

.ui-PrefixNumberField {
  display: inline-flex;
  align-items: center;
  width: 100%;

  &-input {
    flex: 1;
    margin-left: 5px;
  }
}
