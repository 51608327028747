@import '../../variables.css';

.ui-TextField {
  color: var(--color-grey-300);
  background: var(--color-white);
  border: 1px solid var(--color-border);
  padding: 9px 15px;
  border-radius: var(--radius-1);
  font-size: var(--ts-body-1-fs);
  font-weight: normal;
  line-height: 20px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.ui-TextField.error {
  border-color: var(--color-pink-500);
}

.ui-TextField.short {
  width: 150px;
}

.ui-TextField:focus {
  outline: none;
  background: var(--color-white);
  border-color: var(--color-blue-500);
}

.ui-TextField:disabled {
  background: var(--color-grey-100);
  color: var(--color-grey-300);
}

.ui-TextField::placeholder {
  font-weight: var(--book);
  color: var(--color-grey-300);
  transition: color 0.2s;
  opacity: 0.5;
}

.ui-TextField.multiline {
  padding: 12px 15px;
  min-height: 100px;
  resize: vertical;
}

.ui-TextField.monospace {
  font-family: var(--font-mono);
  font-size: var(--ts-body-1-fs);
  font-weight: var(--ts-body-1-fw);
  letter-spacing: var(--ts-body-1-ls);
  line-height: var(--ts-body-1-lh);
}
