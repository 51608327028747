@import '../../variables.css';

/**
 * CheckboxField.
 */

.Checkbox-field {
  margin-bottom: 15px;
}

.Checkbox-field-input {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: var(--color-white) CF7 no-repeat center;
  border: 1px solid var(--color-grey-200);
  border-radius: 2px;
  width: 16px;
  height: 15px;
  vertical-align: middle;
  margin-right: 4px;
  background: white;
}

.Checkbox-field-input:focus {
  outline: none;
}

.Checkbox-field-input:hover,
.Checkbox-field-input:focus {
  border-color: var(--color-blue-500);
}

.Checkbox-field-input:checked {
  background-color: var(--color-blue-500);
  background-image: url('./tick.svg');
  background-repeat: no-repeat;
  background-position: center;
  border-color: var(--color-blue-500);
  background-size: 70%;
}

.Checkbox-field-label {
  color: var(--color-grey-500);
  font-size: var(--ts-display-4-fs);
  font-weight: var(--ts-display-4-fw);
  letter-spacing: var(--ts-display-4-ls);
  line-height: var(--ts-display-4-lh);
  margin-left: 7px;
}

.Checkbox-field .ui-Field-hint {
  margin-top: 0;
  margin-left: 27px;
}
