@import '../../variables.css';

/**
 * Group.
 */

.IntegrationEditor-option {
  margin-top: var(--space-3);
}

.IntegrationEditor-option:first-child {
  margin-top: 0;
}

.GroupField-remove {
  margin-top: var(--space-3);
}

.IntegrationEditor-group--fields {
  border: 1px solid var(--color-border);
  padding: var(--space-4);
  border-radius: var(--radius-1);
  margin-top: var(--space-3);
  margin-bottom: var(--space-3);
  background: white;
  position: relative;
}

.IntegrationEditor-group--fields:first-child {
  margin-top: 0;
}

.IntegrationEditor-group--button {
  color: var(--color-blue-500);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-3);
}

.IntegrationEditor-group--button:hover,
.IntegrationEditor-group--button:focus {
  color: var(--color-blue-300);
}

.IntegrationEditor-group--button-text {
  margin-left: var(--space-2);
}

.GroupField-remove {
  margin-left: var(--space-3);
  height: 16px;
  width: 16px;
  background: url('../map/remove.svg') no-repeat;
  border: 0;
  text-indent: -9999px;
  position: absolute;
  top: 0;
  right: 14px;
}

.IntegrationEditor-group--button-muted {
  color: var(--evergreen-neutral-50);
  cursor: not-allowed;
}
