@import '../../variables.css';

/**
 * MultiField.
 */

.Multi-item {
  display: flex;
  margin-top: 8px;
}

.Multi-itemValue {
  flex-grow: 1;
}

.Multi-field-hint {
  color: var(--color-grey-300);
  display: block;
  font-size: var(--ts-body-2-fs);
  font-weight: var(--ts-body-2-fw);
  letter-spacing: var(--ts-body-2-ls);
  line-height: var(--ts-body-2-lh);
  margin-top: 10px;
}

.Multi-itemRemove {
  margin-left: 5px;
  margin-top: 9px;
}
