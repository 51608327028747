/* Matching Evergreen to Paste that has a z-index of 80 in order to display. This is used to help display evergreen and paste components together. */
[evergreen-portal-container] {
  z-index: 80 !important;
  position: fixed;
  top: 0;
  left: 0;
}

/* Matching Evergreen to Paste for toaster container having a z-index of 90. 90 is what Paste Toasters are set as. */
[data-evergreen-toaster-container] {
  z-index: 90 !important;
  position: fixed;
  top: 0;
  left: 0;
}
