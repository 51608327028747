@import './variables.css';

/**
 * Headings
 */

h1 {
  color: var(--color-title-text);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.07px;
  line-height: 28px;
}

h2,
h3 {
  color: var(--color-title-text);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.07px;
  line-height: 24px;
}

h4,
h5,
h6 {
  color: var(--color-title-text);
  font-size: var(--ts-display-4-fs);
  font-weight: var(--ts-display-4-fw);
  letter-spacing: var(--ts-display-4-ls);
  line-height: var(--ts-display-4-lh);
}
