@import '../../variables.css';

/**
 * MapField.
 */

.Map-item {
  display: flex;
  align-items: center;
  margin-top: var(--space-3);
}

.Map-item:first-child {
  margin-top: 0;
}

.Map-item:first-child:last-child .Map-item-remove {
  opacity: 0.4;
}

.Map-item-key {
  background: url('./caret-right.svg') no-repeat 100% 50%;
  padding-right: 20px;
  margin-right: 10px;
  flex-grow: 1;
}

.Map-item-value {
  flex-grow: 1;
}

.Map-field-hint {
  color: var(--color-grey-300);
  display: block;
  font-size: var(--ts-body-1-fs);
  font-weight: var(--ts-body-1-fw);
  letter-spacing: var(--ts-body-1-ls);
  line-height: var(--ts-body-1-lh);
  margin-top: var(--space-3);
}

.Map-item-remove {
  margin-left: var(--space-3);
  height: 16px;
  width: 16px;
  background: url('./remove.svg') no-repeat;
  border: 0;
  text-indent: -9999px;
}

.Map-field-maps > .ui-Field > .ui-Field-control {
  background: white;
  padding: 14px;
  border: 1px solid var(--color-border);
  border-radius: var(--radius-1);
  margin-top: var(--space-3);
  display: block;
}

.Map-field-actions {
  color: var(--color-blue-500);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-2);
}

.Map-field-actions:hover,
.Map-field-actions:focus {
  color: var(--color-blue-300);
}

.Map-field-actions-text {
  margin-left: var(--space-2);
}

.Map-field-actions-muted {
  color: var(--evergreen-neutral-50);
  cursor: not-allowed;
}
