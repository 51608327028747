@import '../../variables.css';

.ui-Field {
  display: block;
  font-size: 20px;
  position: relative;
}

.ui-Field + .ui-Field {
  margin-top: 15px;
}

.ui-Field:last-child {
  margin-bottom: 0;
}

.ui-Field-control {
  position: relative;
}

.ui-Field-flex {
  display: flex;
}

.ui-Field-flex button {
  margin-left: 10px;
}

.ui-Field-label {
  color: var(--color-grey-500);
  display: block;
  font-size: var(--ts-display-4-fs);
  font-weight: var(--ts-display-4-fw);
  letter-spacing: var(--ts-display-4-ls);
  line-height: var(--ts-display-4-lh);
  margin-bottom: var(--space-2);
}

.ui-Field-hint {
  color: var(--color-grey-300);
  display: block;
  font-size: var(--ts-body-1-fs);
  font-weight: var(--ts-body-1-fw);
  letter-spacing: var(--ts-body-1-ls);
  line-height: var(--ts-body-1-lh);
  margin-top: var(--space-2);
}

.ui-Field-hint strong,
.ui-Field-hint em {
  font-weight: var(--medium);
  font-style: normal;
  color: var(--color-grey-500);
}

.ui-Field-hint a {
  color: var(--color-blue-500);
  text-decoration: underline;
}

.ui-Field.showErrors .ui-Field-error {
  display: block;
}

.ui-Field-error {
  color: var(--color-pink-500);
  display: none;
  font-size: var(--ts-body-1-fs);
  font-weight: var(--ts-body-1-fw);
  letter-spacing: var(--ts-body-1-ls);
  line-height: var(--ts-body-1-lh);
  margin-bottom: -5px;
  margin-top: var(--space-3);
  position: relative;
  transition: all 200ms ease-in-out;
}
