@import '../../variables.css';

.ui-Select {
  -webkit-appearance: none;
  appearance: none;
  height: 37px;
  line-height: 1;
  padding-right: 35px;
  cursor: pointer;
  background: var(--color-white);
  color: var(--color-grey-500);
  border: 1px solid var(--color-border);
  padding: 9px 15px;
  border-radius: var(--radius-1);
  font-size: var(--ts-body-1-fs);
  width: 100%;
  box-sizing: border-box;
}

.ui-SelectCaret {
  display: block;
  position: absolute;
  top: 11px;
  right: var(--space-3);
  color: var(--color-grey-300);
  pointer-events: none;
}
